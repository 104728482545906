<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="closeDialog()"
    :show-close="false"
    width="520px"
    class="bank-dialog"
  >
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>开通结算中心</span>
      </div>
      <div class="right_icon">
        <span></span>
      </div>
    </div>
    <el-card class="bank-card">
      <div
        v-for="(item, indx) in userBankList"
        :key="indx"
        style="display:flex;justify-content: space-between;"
      >
        <div class="del-pop">
          <img src="../../assets/Select.svg" alt v-if="!item.open" style="margin:0 10px" />
          <img src="../../assets/Select_show.svg" alt v-if="item.open" style="margin:0 10px" />
          {{ item.label }}
        </div>
        <el-checkbox v-model="item.open" :disabled="item.disabled" @change="itemChange(item)"></el-checkbox>
      </div>
    </el-card>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="submit()" :disabled="checkList.length == 0">确 定</el-button>
      <el-button @click="closeDialog()">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { api } from '/src/api/base';
export const MybankAccountList = api()('mybank.account.list.json', 'mybankAccountUserList');
export const accountCreate = api()('account.create.json');
export default {
  props: {
    code: String,
    dialogVisible: Boolean
  },
  data () {
    return {
      userBankList: [],
      platfromList: [],
      List: [],
      disabled: true,
      imgList: [],
      checkList: []
    };
  },
  watch: {
    code: async function (val, old) {
      if (val === old) return;
      await this.getMyBankList();
    }
  },
  created () {
    this.userBankList = this.$route.params.$preload.platformList.map(it => ({
      label: it.shortname,
      value: it.pay_platform,
      open: false,
      disable: false
    }));
  },
  methods: {
    async getMyBankList () {
      const res = await MybankAccountList({ subjectCode: this.code });
      this.userBankList.forEach(it => {
        it.open = res.findIndex(_it => _it.pay_platform === it.value) > -1;
        it.disabled = it.open;
      });
      this.checkList = this.userBankList
        .filter(it => it.open)
        .map(it => it.shortname);
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
    },
    itemChange (item) {
      if (item.open) {
        this.checkList.push(item.value);
      } else {
        this.checkList = this.checkList.filter(it => it != item.value);
      }
      this.checkList = this.checkList.filter(it => it);

    },
    async submit () {
      await accountCreate({
        code: this.code,
        pay_platforms: this.checkList.join(',')
      });
      this.$message.success('结算中心开通成功！');
      this.closeDialog();
    }
  }
};
</script>
<style>
.bank-check .el-checkbox-group {
  position: relative;
}
.bank-check .el-checkbox__input {
  position: absolute;
  right: 40px;
}
.bank-card .el-card__body {
  text-align: left;
}
.bank-card .el-checkbox {
  border-bottom: 1px dashed #7d7d7d;
  padding-bottom: 10px;
}
.bank-card .el-checkbox:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.bank-card .el-checkbox__inner {
  border-radius: 50%;
  width: 17px;
  height: 17px;
}
.bank-card .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: #19588c;
}
.bank-card .el-checkbox__inner::after {
  width: 7px;
  height: 7px;
  background-color: #19588c;
  top: 4px;
  border-radius: 50%;
}
.bank-card .el-checkbox__input.is-checked .el-checkbox__inner,
.bank-card .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f2f6fc;
  border-color: #19588c;
}

.bank-dialog .el-dialog__body {
  padding: 20px 40px 20px;
}
</style>
<style lang="scss" scoped>
.el-checkbox {
  margin-bottom: 15px;
  margin-top: 15px;
}
.del-pop {
  img {
    width: 26px;
    height: 26px;
  }
}
</style>
